<template>
  <div class="">
    <center-title title="履行时效"
      ><el-button
        type="primary"
        class="whtie submit"
        @click="exportConsumeRecord"
        >导出</el-button
      >
    </center-title>

    <el-table :data="LimitationPerformance">
      <el-table-column
        align="center"
        prop="name"
        label="品类"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="snnumber"
        label="订单总数"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="down_time"
        label="24小时内完成配送订单数量"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="down_roportion"
        label="24小时内占比"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="up_time"
        label="超24小时配送订单数量"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="up_proportion"
        label="超24小时占比"
        width="180px"
      ></el-table-column>
    </el-table>

    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import { download } from "@/utils/plugin";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      likename: [],
      trueuser: [],
      page_no: 1,
      page_size: 15,
      LimitationPerformance: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        date: [],
        start_time: "",
        end_time: "",
      },
      orderObject: {},
      auditBox: {
        id: "",
        type: 1,
        reject_reason: "",
      },
      uploadPay: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    exportConsumeRecord() {
      this.$api;
      download(
        "/LimitationPerformance/goodsExcel",
        {
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        },
        `履约时效${new Date().getTime()}.xlsx`
      );
    },
    successUpload(res) {
      let { code, data } = res;
      this.uploadPay = res.data.uri;
    },
    onSubmit() {},
    resetList(e) {
      this.page_no = e;
      this.getLimitationPerformance();
    },
    /**
     * @description 获取列表
     * @returns void
     */
    getLimitationPerformance() {
      this.$api
        .getLimitationPerformanceApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        })
        .then((res) => {
          this.LimitationPerformance = res.data.order;

          let { order, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            order,
            count,
          };
        });
    },
    setRmOperate() {
      this.$api.setRmOperateApi(this.auditBox).then((res) => {
        if (res.code == 1) {
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
    resetList(e) {
      this.page_no = e;
      this.getLimitationPerformance();
    },
  },
  created() {
    this.getLimitationPerformance();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}

.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
